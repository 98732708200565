<template>
 <router-view/>
</template>

<script>


export default {

}
</script>

<style lang="scss">


@font-face {
	font-family: 'Montserrat-Regular';
	src: url('/public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype'); 
   font-display: swap;
}

@font-face {
	font-family: 'Montserrat-Bold';
	src: url('/public/fonts/montserrat/Montserrat-Bold.ttf') format("truetype");
	font-display: swap;
}


@mixin font-light {
	font-family: 'Montserrat-Light', sans-serif !important;
}

@mixin font-regular {
	font-family: 'Montserrat-Regular', sans-serif !important;
}

@mixin font-bold {
	font-family: 'Montserrat-Bold', sans-serif !important;
}

@mixin font-extra-bold {
	font-family: 'Montserrat-ExtraBold', sans-serif !important;
}



@mixin small-mobile-screen {
	@media only screen and (max-width: 360px) {
		@content;
	}
}

@mixin mobile-screen {
	@media only screen and (max-width: 576px) {
		@content;
	}
}

@mixin intermediate-mobile-and-tablet {
	@media only screen and (min-width: 577px) and (max-width: 767px) {
		@content;
	}
}

@mixin tablet-screen-portrait {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin tablet-screen-landscape {
	@media only screen and (min-width: 960px) {
		@content;
	}
}

@mixin medium-laptop-screen {
	@media only screen and (max-width: 960px) {
		@content;
	}
}

@mixin medium-screen {
	@media only screen and (min-width: 992px) {
		@content;
	}
}

@mixin desktop-screen {
	@media only screen and (min-width: 1200px) {
		@content;
	}
}

@mixin large-desktop-screen {
	@media only screen and (min-width: 1400px) {
		@content;
	}
}

$pure-white: #FFF;
$pure-black: #000;
$primary-color: #00b4b4;
$secondary-color: #3A3A3A;
$footer-para: #525252;


button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-floating > label {
  font-weight: 300;
  color: #3A3A3A !important;
}

.form-control {
  font-weight: 600 !important;
  color: #3A3A3A !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #3A3A3A !important;
  background: transparent;
  border-radius: 0px !important;
}

.form-control:focus {
  border-color: #00b4b4 !important;
  box-shadow: none !important;
  background: transparent !important;
}

textarea {
  resize: none !important;
}

::selection {
  background: #00b4b4;
  color: #3A3A3A;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #3A3A3A;
}

::-moz-selection {
  background: #3A3A3A;
  color: #00b4b4;
}

h1 {
  font-weight: bold;
  color: #3A3A3A;
  font-size: 50px;
}

h1 {
  @include font-bold;
  color: $secondary-color;
  font-size: 50px;

  @include mobile-screen {
    font-size: 40px
  }
}

h2 {
  @include font-bold;
  color: $secondary-color;
  font-size: 50px;

  @include mobile-screen {
    font-size: 40px;
  }
}

h3 {
  @include font-bold;
  color: $secondary-color;
  font-size: 50px;

  @include mobile-screen {
    font-size: 40px;
  }
}

h4 {
  @include font-bold;
  color: $secondary-color;
  font-size: 35px;
}

h5 {
  @include font-bold;
  color: $secondary-color;
  font-size: 25px;

  @include mobile-screen {
    font-size: 20px;
  }
}

p {
  line-height: 30px;
  color: $secondary-color;

  @include small-mobile-screen {
    line-height: 25px;
  }

  @include mobile-screen {
    line-height: 27px;
  }
}
p {
  line-height: 30px;
  color: #3A3A3A;
}

@media screen and (max-width: 480px) {
  p {
    line-height: 25px;
  }
}

@media screen and (max-width: 768px) {
  p {
    line-height: 27px;
  }
}

.card {
  border: none !important;
}


.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-150px);
  transition: all 0.3s;
  transition-delay: 150ms;
}

@media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0px);
}

.list-group-item {
  border: none !important;
  padding: 20px !important;
  margin: 20px 0px !important;
  border-radius: 10px !important;
  transition: all 0.3s;
  background-color: transparent !important;
  transition-delay: calc(50ms * var(--order));
}

@media screen and (max-width: 768px) {
  .list-group-item {
    padding: 15px !important;
    margin: 5px 0px !important;
  }
}

.list-group-item.active {
  box-shadow: 3px 3px 6px
  0px #CCDBE8 inset, -3px -3px 6px 1px rgba(255, 255, 255, 0.5) inset;
  background-color: transparent !important;
  color: #3A3A3A !important;
  font-weight: bold;
}

.tab-content {
  padding: 50px 20px;
  height: 500px;
}

@media screen and (max-width: 768px) {
  .tab-content {
    height: max-content;
  }
}

.tab-content .article {
  display: grid;
  justify-content: center;
  align-items: center;
}

.error-msg {
  padding-left: 10px;
  color: #fe2122;
  font-weight: bold;
}

.error-msg.contact-us {
  color: #ff7575;
}

.svgIconImg{
    color: #00b4b4;
    font-size: 50px;
    padding: 25px 0px;
}

.sendMsgBtn{
    border-radius: 40px;
    background: #00b4b4;
    color: #fff;
    padding: 12px 22px;
    font-size: 15px;
    border: none;
}



</style>
