<template>
<div style="overflow-x: hidden;">
<nav id="main-navbar" class="navbar navbar-expand-lg p-0">
	<div class="container-fluid justify-content-between">
		<a class="navbar-brand p-3 me-auto" href="./index.html" title="Plugzmart EV Charger Manufacturer">
			<img id="companyLogo" src="/images/logo.png" alt="Plugzmart logo">
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mx-auto mb-2 mb-lg-0">
				<li class="nav-item"> 			
      <router-link class="nav-link" to="/"  title="Plugzmart EV Charger Manufacturer"  >Home</router-link>
				</li>
				<li class="nav-item">
					<!-- <a class="nav-link" id="about-link" href="./about">About Us</a> -->
           <router-link class="nav-link" to="/about" title="About Us">About Us</router-link>
				</li>
				<li class="nav-item dropdown dropdown-hover">
					<a class="nav-link dropdown-toggle" role="button" id="dropdownMenuProducts" data-bs-toggle="dropdown"
						aria-expanded="false">Products</a>
					<div class="dropdown-menu products dropdown-menu-animation border-4 mt-0 mt-lg-3"
						aria-labelledby="dropdownMenuProducts" data-bs-popper="static">
						<div class="row">
							<div class="col-12 px-4 py-2">
								<div class="row">
									<div class="col-12">
										<!-- <a class="dropdown-item" href="./ac-chargers">
											<span class="ps-3">
												AC Chargers
											</span>
										</a> -->   
                    <router-link class="nav-link" to="/ac-chargers" title="AC Chargers For EV">AC Chargers</router-link>
                       <router-link class="nav-link" to="/dc-chargers" title="DC Chargers For EV">DC Chargers</router-link>
                          <router-link class="nav-link" to="/portable-chargers" title="Portable Chargers For EV">Portable EV Chargers</router-link>
										<!-- <a class="dropdown-item" href="./dc-chargers">
											<span class="ps-3">
												DC Chargers
											</span>
										</a> -->
										<!-- <a class="dropdown-item" href="./portable-chargers">
											<span class="ps-3">
												Portable EV Chargers
											</span>
										</a> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
				<li class="nav-item">
				<!-- <a class="nav-link" id="service-link" href="./services">Services</a> -->
        <router-link class="nav-link" to="/services" title="Our Services" >Services</router-link>
				</li>
				<li class="nav-item">
					<a class="nav-link" id="blog-link" href="https://blog.plugzmart.com/" title="Blogs On EV Charging Solutions" >Blog</a>
				</li>
				<li class="nav-item d-block d-sm-block d-md-block d-lg-none">
					<!-- <a class="nav-link" id="contact-link" href="./contact-us">Get a Quote</a> -->
           <router-link class="nav-link" to="/contact-us" title="Reach Us For EV Solutions" >Get a Quote</router-link>
				</li>
			</ul>
			<ul class="navbar-nav d-lg-block d-none">
				<li class="nav-item nav-cta">
					<!-- <a type="button" href="./contact-us">
						Contact Us
 <font-awesome-icon  :icon="['fas', 'circle-arrow-right']" />
					</a> -->
           <router-link class="nav-link" to="/contact-us" title="Reach Us For EV Solutions">Contact Us  <font-awesome-icon  :icon="['fas', 'circle-arrow-right']" /></router-link>
				</li>
			</ul>
		</div>
	</div>
</nav>
</div>

</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleArrowRight   } from '@fortawesome/free-solid-svg-icons';

library.add(faCircleArrowRight  );

export default {
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
/* Your styles here */

// @font-face {
// 	font-family: 'Montserrat-Regular';
//  src: url('/public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
// 	font-display: swap;
// }
@font-face {
    font-family: 'Montserrat-Regular';
    src: url('/public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
   
}

@font-face {
	font-family: 'Montserrat-Bold';
	src: url('/public/fonts/montserrat/Montserrat-Bold.ttf') format("truetype");
	font-display: swap;
}

// @font-face {
// 	font-family: 'Montserrat-ExtraBold';
// 	src: url(./assets/font/montserrat/Montserrat-ExtraBold.ttf) format("opentype");
// 	font-display: swap;
// }

@mixin font-light {
	font-family: 'Montserrat-Light', sans-serif !important;
}

@mixin font-regular {
	font-family: 'Montserrat-Regular', sans-serif !important;
}

@mixin font-bold {
	font-family: 'Montserrat-Bold', sans-serif !important;
}

@mixin font-extra-bold {
	font-family: 'Montserrat-ExtraBold', sans-serif !important;
}


@mixin small-mobile-screen {
	@media only screen and (max-width: 360px) {
		@content;
	}
}

@mixin mobile-screen {
	@media only screen and (max-width: 576px) {
		@content;
	}
}

@mixin intermediate-mobile-and-tablet {
	@media only screen and (min-width: 577px) and (max-width: 767px) {
		@content;
	}
}

@mixin tablet-screen-portrait {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin tablet-screen-landscape {
	@media only screen and (min-width: 960px) {
		@content;
	}
}

@mixin medium-laptop-screen {
	@media only screen and (max-width: 960px) {
		@content;
	}
}

@mixin medium-screen {
	@media only screen and (min-width: 992px) {
		@content;
	}
}

@mixin desktop-screen {
	@media only screen and (min-width: 1200px) {
		@content;
	}
}

@mixin large-desktop-screen {
	@media only screen and (min-width: 1400px) {
		@content;
	}
}

$pure-white: #FFF;
$pure-black: #000;
$primary-color: #00b4b4;
$secondary-color: #3A3A3A;
$footer-para: #525252;


nav {
  margin: 0px 250px !important;
  height: 70px;
  position: fixed !important;
  border-radius: 40px;
  top: 15px;
  right: 0px;
  left: 0px;
  z-index: 1030;
  transition: all 0.3s !important;
  background: hsla(0, 0%, 100%, .8) !important;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);

  // @media only screen and (min-width: 960px) and (max-width: 991px) {
  //   margin: 0px 75px !important;
  // }

  @include tablet-screen-landscape {
    margin: 0px 100px !important;
    height: max-content;
  }

  @include mobile-screen {
    margin: 0px !important;
    width: 100vw;
    height: max-content;
    top: 15px;
  }

  @include medium-laptop-screen {
    margin: 0px !important;
    width: 100vw;
    height: max-content;
  }

  @include medium-screen {
    margin: 0px 100px !important;
  }

  @include desktop-screen {
    margin: 0px 100px !important;
  }

  @include large-desktop-screen {
    margin: 0px 200px !important;
  }

  @media only screen and (min-width: 992px) and (max-width: 1354px) {
    margin: 0px 50px !important;
  }

  & .navbar-brand {
    margin-left: 30px;

    @include mobile-screen {
      margin-left: 0px !important;
    }

    & img {
      width: 200px;

      @include mobile-screen {
        width: 180px;
      }

      @include intermediate-mobile-and-tablet {
        width: 180px;
      }

      @include tablet-screen-portrait {
        width: 180px;
      }

      @include desktop-screen {
        width: 200px;
      }
    }
  }
  @include font-regular 

}

.navbar-nav {
  &>.nav-item {
    margin: 0px 15px;

    &:last-child {
      margin-right: 0px
    }

    &>.nav-link {
      font-weight: 600 !important;
      color: #67748e !important;
      border-radius: 0.5rem;
      padding: 0.6rem 1rem;
      font-size: 15px;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;

      &:hover {
        color: $primary-color !important;
      }
    }

    &.nav-cta>a {
      text-decoration: none;
      transition: 0.25s linear;
      border-radius: 40px;
      color: $pure-white !important;
      background: $primary-color;
      padding: 12px 22px;
      font-size: 15px;

      @media only screen and (min-width: 992px) and (max-width: 1033px) {
        padding: 5px 0px !important;
      }

      &:hover {
        background: $secondary-color;
        box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%),
          0 8px 9px -5px hsl(0deg 0% 8% / 6%);

        &>span {
          &>.right-icon {
            display: none;
          }

          &>.arrow-icon {
            display: inline;
          }
        }
      }
    }

    &>.active {
      color: $primary-color !important;
    }
  }
}

.dropdown-menu.products {
  min-width: 17rem;

  @include mobile-screen {
    min-width: max-content;
  }

  @include medium-laptop-screen {
    min-width: max-content;
  }
}

.dropdown-header {
  display: block;
  padding: 1rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown {

  &.dropdown-hover:hover>.dropdown-menu,
  .dropdown,
  .dropdown-menu.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 37px, 5px) !important;
  }

  &.dropdown-hover .dropdown-menu,
  .dropdown .dropdown-menu {
    display: block;
    opacity: 0;
    top: -13px;
    transform-origin: 50% 0;
    pointer-events: none;
    transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0, 37px, 0) !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, box-shadow;
    border: none;

    & .dropdown-item {
      border-radius: 0.5rem;
      transition: background-color .3s ease, color .3s ease;
      display: block;
      width: 100%;
      padding: 5px 5px !important;
      clear: both;
      font-size: 14px;
      // color: #67748e;
      color: #4f596d;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;

      &:hover {
        color: #344767;
        background-color: #E9ECEF;
      }
    }
  }

  &.dropdown-hover .dropdown-menu.products,
  .dropdown .dropdown-menu {
    left: -50px;

    @include mobile-screen {
      left: 10px;
    }

    @include medium-laptop-screen {
      left: 10px;
    }

    @media only screen and (min-width: 960px) and (max-width: 991px) {
      left: 10px;
    }
  }

  & .dropdown-menu,
  .dropup .dropdown-menu {
    transition: visibility .25s, opacity .25s, transform .25s;
  }

  & .dropdown-menu,
  .dropdown .dropdown-menu,
  .dropstart .dropdown-menu,
  .dropup .dropdown-menu {
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%),
      0 8px 9px -5px hsl(0deg 0% 8% / 6%);
    cursor: pointer;

    @include mobile-screen {
      box-shadow: none;
    }

    @include medium-laptop-screen {
      box-shadow: none;
    }
  }
}

.dropdown-header {
  font-size: 16px;
}

.navbar-toggler {
  border: none !important;
  transition: all 0.25s;

  &:focus {
    box-shadow: 3px 3px 6px 0px #CCDBE8 inset,
      -3px -3px 6px 1px rgba(255, 255, 255, 0.5) inset !important;
  }
}

.navbar-toggler+.navbar-collapse .dropdown.nav-item .dropdown-menu {
  @media only screen and (min-width: 960px) and (max-width: 991px) {
    background-color: transparent;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    transform: none !important;
    box-shadow: none !important;
  }

  @include mobile-screen {
    background-color: transparent;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    transform: none !important;
    box-shadow: none !important;
  }

  @include medium-laptop-screen {
    background-color: transparent;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    transform: none !important;
    box-shadow: none !important;
  }
}

.dropdown.nav-item .dropdown-menu-animation.show {
  @media only screen and (min-width: 960px) and (max-width: 991px) {
    height: 130px;
    opacity: 1;
  }

  @include mobile-screen {
    height: 130px;
    opacity: 1;
  }

  @include medium-laptop-screen {
    height: 130px;
    opacity: 1;
  }
}
// PRODUCTS 
.dropdown.nav-item .ud-1.show{
  @media only screen and (min-width: 960px) and (max-width: 991px) {
    height:290px;
    opacity: 1;
  }

  @include mobile-screen {
    height: 260px !important;
    // background-color: red !important;
    padding: 0 !important;
    width:70vw !important;
    opacity: 1; 
    overflow-y: scroll !important;
    & p {
      // font-size: 12px !important;
      margin-bottom: 1px !important;
      padding-right: 0 !important;
      padding-left:3px !important;
      border-radius:5px !important;
    }
    & header {
      & span {
        & a{
          font-size: 1rem !important;
        }
  
      }
    }
  
    & a {
      // font-size: 12px !important;
      margin-bottom: 1px !important;
    }

    #menu{
      width:50px;
      margin:.45rem !important;
      // margin-left: -5px !important;
    }
    #mega-menu header {
      // font-size: 0.5rem !important; //not applying
      @include font-bold;
  }
  #mega-menu{
    .container-4{
      display: none !important;
    }
  }
  #inner-container{
    display:block !important;
    margin-top:20px;
    // line-height: 30px;
    padding-left: 0;
    // font-size: 0.15rem !important; not working
    max-width:35vw;
    // background-color: green;
  }

}

  @include medium-laptop-screen {
    height: 290px;
    opacity: 1;
    #menu{
      width:50px;
    }
  }
}

// .ud-2{
//   display: flex !important;
//   flex-direction: column !important;
// }

:not(.navbar) .dropdown.nav-item .dropdown-menu {
  @media only screen and (min-width: 960px) and (max-width: 991px) {
    position: absolute;
  }

  @include mobile-screen {
    position: absolute;
  }

  @include medium-laptop-screen {
    position: absolute;
  }
}

:not(.navbar) .dropdown .dropdown-menu.show {

  @media only screen and (min-width: 960px) and (max-width: 991px) {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  @include mobile-screen {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  @include medium-laptop-screen {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
}

.dropdown.nav-item .dropdown-menu-animation {
  @media only screen and (min-width: 960px) and (max-width: 991px) {
    display: block;
    height: 0;
    transition: all .35s ease;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0;
  }

  @include mobile-screen {
    display: block;
    height: 0;
    transition: all .35s ease;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0;
  }

  @include medium-laptop-screen {
    display: block;
    height: 0;
    transition: all .35s ease;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0;
  }
}

:not(.navbar) .dropdown .dropdown-menu {
  @media only screen and (min-width: 960px) and (max-width: 991px) {
    opacity: 0;
    top: 0;
    transform-origin: 50% 0;
    pointer-events: none;
    transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0, 37px, 0) !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, box-shadow;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%),
      0 8px 9px -5px hsl(0deg 0% 8% / 6%);
  }

  @include mobile-screen {
    opacity: 0;
    top: 0;
    transform-origin: 50% 0;
    pointer-events: none;
    transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0, 37px, 0) !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, box-shadow;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%),
      0 8px 9px -5px hsl(0deg 0% 8% / 6%);
  }

  @include medium-laptop-screen {
    opacity: 0;
    top: 0;
    transform-origin: 50% 0;
    pointer-events: none;
    transform: perspective(999px) rotateX(-10deg) translateZ(0) translate3d(0, 37px, 0) !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, box-shadow;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%),
      0 8px 9px -5px hsl(0deg 0% 8% / 6%);
  }
}

.heading_banner{
  justify-content: center;
    align-items: center;
    display: flex;
    background: #2CBFC9;
    min-height: 400px;
}
.white_color{
  color: #fff;
}
.margin_top_20{
  margin-top:20px;
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-sm, .navbar>.container-md, .navbar>.container-lg, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}

nav {
    // margin: 0px 250px !important;
    height: 70px;
    position: fixed !important;
    border-radius: 40px;
    top: 15px;
    right: 0px;
    left: 0px;
    z-index: 1030;
    transition: all .3s !important;
    background: rgba(255, 255, 255, .8) !important;
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
}





</style>
