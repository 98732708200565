import { createRouter, createWebHistory } from 'vue-router';
// import Home from './views/Home.vue';
// import About from '../components/About.vue';
// import Home from '../vies/Home.vue';
import content from './views/content.vue'

const routes = [
  {
    path: "",
    name: "content",
    component: content,
    children: [
      {
        path: '',
        meta: { title: 'Plugzmart | Top EV Charger Manufacturer In India' },
        component: () => import(/* webpackChunkName: "dashboard" */ './views/Home.vue'),
      },
      {
        path: 'about',
        meta: { title: 'Plugzmart | About Us' },
        component: () => import(/* webpackChunkName: "dashboard" */ './views/about.vue'),
      },
      {
        path: 'services',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/services.vue'),
      },
      {
        path: 'contact-us',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/contact-us.vue'),
      },
      {
        path: 'ac-chargers',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/ac-chargers.vue'),
      },
      {
        path: 'dc-chargers',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/dc-chargers.vue'),
      },
      {
        path: 'privacy-policy',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/privacy-policy.vue'),
      },

      {
        path: 'terms-and-conditions',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/terms-and-conditions.vue'),
      },


      {
        path: 'charge-details',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/charge-details.vue'),
      },
      {
        path: 'portable-chargers',

        component: () => import(/* webpackChunkName: "dashboard" */ './views/portable-chargers.vue'),
      },

    ]
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the route has a meta title property
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    // Set a default title if the route doesn't have a title
    document.title = 'Best EV Charger Manufacturer in India | EV Charging Stations India';
  }
  next();
});


export default router;